import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import GraphQLErrorList from '../../components/graphql-error-list';
import { Layout, LanguageContext } from '../../layout/Layout';
import SEO from '../../components/seo';
import { usePageTitle } from '../../hooks/usePageTitle';
import EmployeeList from '../../components/EmployeeList';
import HeaderPadding from '../../layout/HeaderPadding';
import { EmployeeFilter } from '../../components/EmployeeFilter';
import { useContext } from 'react';
import styled from 'styled-components';
import { above } from '../../styles/breakpoints';
import { Block, Width } from '@components/Blocks';
import { employeeSort, groupSort } from '@lib/employees';

export const query = graphql`
  query EmployeesPageQueryNb {
    groups: allSanityGroup(sort: { fields: [title___nb] }) {
      edges {
        node {
          _id
          _rawTitle
        }
      }
    }
    employees: allSanityEmployee(sort: { fields: [firstName], order: ASC }) {
      edges {
        node {
          _id
          firstName
          lastName
          email
          phoneNumber
          jobTitle
          image {
            asset {
              _id
            }
          }
          employeeGroups {
            _id
          }
        }
      }
    }
  }
`;

const employeeFilterSearch = (search) => (employee) => {
  const haystack = employee.firstName + employee.lastName + employee.jobTitle;

  return haystack.toLowerCase().includes(search.toLowerCase());
};
const employeeFilterGroup = (group) => (employee) => employee.employeeGroups.some((g) => g._id == group);

/**
 *
 * @param {string | null} search
 * @param {string | null} group
 */
const employeeFilters = (search, group) => {
  if (search) {
    return employeeFilterSearch(search);
  }

  if (group) {
    return employeeFilterGroup(group);
  }

  return () => true;
};

const Wrapper = styled.div`
  ${above.fullHD`
      font-size: 2rem;
    `}
`;

const EmployeesPage = (props) => {
  const { data, errors } = props;

  const lang = useContext(LanguageContext);
  const title = lang === `nb` ? `Menneskene` : 'People';

  usePageTitle(title);

  const [filterGroup, setFilterGroup] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  let employees = data.employees.edges
    .map((e) => e.node)
    .map((e) => ({ ...e, image: e.image && e.image.asset && e.image.asset._id }))
    .filter(employeeFilters(filterSearch, filterGroup))
    .sort(employeeSort);

  const groups = data.groups.edges
    .map((e) => e.node)
    .map((g) => ({
      _id: g._id,
      title: g._rawTitle[lang],
      selected: filterGroup === g._id,
    }))
    .sort(groupSort);

  return (
    <Fragment>
      <SEO title={title} />
      <HeaderPadding />
      <Wrapper style={{ width: '100%', height: '100%' }}>
        <Block>
          <Width type="h">
            <EmployeeFilter groups={groups} setFilterGroup={setFilterGroup} setFilterSearch={setFilterSearch} />
          </Width>
        </Block>
        <Block type="s">
          <Width type="h">
            <EmployeeList data={employees} />
          </Width>
        </Block>
      </Wrapper>
    </Fragment>
  );
};

export default EmployeesPage;
